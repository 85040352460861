@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech&display=swap');
@import url("https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css");

html {
scroll-behavior: smooth;
}

body {
  color: white;
  font-family: 'Poppins', sans-serif;
  background-color: #F7F7F9;
}

#techBox {
  font-family: 'Share Tech', sans-serif;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
}
 
::-webkit-scrollbar-thumb { 
  border-radius: 6px;
  border: 2px solid transparent;
   background-clip: content-box;
    background-color: #8C62AD;
}

.scrollAnimate {
  opacity: 0;
  transform: translateY(300px);
  transition: all 0.4s ease-out;
  transition-delay: 0.2s;
}


.slide-in {
  transform: translateY(0);
  opacity: 1;
}